import logo from './jpg/contact.jpg';
import bgCapabilities from './jpg/slide-3.jpg';
import headerWithUs from './jpg/header-with-us.jpg';
import slide1 from './jpg/slide-1.jpg';
import slide2 from './jpg/slide-2.jpg';
import bgAboutUs from './png/5.jpg';
import bgSkill from './png/4.jpg';
import bgBlog from './png/2.jpg';
import bgService from './png/bg-service.jpg';
import bgHomeContact from './png/bg-contact.jpg';
import bgHomeCustomer from './png/bg-customer-1.jpg';

export { logo, bgCapabilities, headerWithUs, slide1, slide2, bgAboutUs, bgSkill, bgBlog, bgService, bgHomeContact, bgHomeCustomer };
