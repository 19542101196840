import React, { Component } from "react";
import {
  Carousel
} from "react-bootstrap";
import { bgCapabilities, slide2, slide1 } from '../../images';
import { BackgroundImages } from "../../shared";
import { Fade } from 'react-slideshow-image';

class SlideShow extends Component {
  componentDidMount() {
    const ImagePreload = new Image();
    ImagePreload.src = bgCapabilities;
  }

  render() {
    const slideImages = [
      bgCapabilities,
      slide2,
      slide1
    ];

    const fadeProperties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: false,
    }

    return (
      <div className="slideshow-header">
        <div id="preload">
          <img src={bgCapabilities} />
        </div>
        <div className="hidden-desktop">
          <Fade {...fadeProperties}>
            <div className="each-slide">
              <div style={{'backgroundImage': `url(${slideImages[0]})`}}>
                <div className="carousel-caption-1">
                  <p className="title-banner">OPENTECHIZ</p>
                  <p className="p-banner">Effective Team-Working</p>
                  <div className="clear" />
                </div>
              </div>
            </div>
            <div className="each-slide">
              <div style={{'backgroundImage': `url(${slideImages[1]})`}}>
                <div className="carousel-caption-2">
                  <p className="title-banner">OPENTECHIZ</p>
                  <p className="p-banner">We use the best technologies for <br/> the development of Web apps and Mobile Apps</p>
                </div>
              </div>
            </div>
            <div className="each-slide">
              <div style={{'backgroundImage': `url(${slideImages[2]})`}}>
                <div className="carousel-caption-3">
                  <p className="title-banner">OPENTECHIZ</p>
                  <p className="p-banner">Always think with our clients</p>
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <Carousel className="slide-show-reposive">
          <Carousel.Item>
            <BackgroundImages style={{
              backgroundImage: `url(${bgCapabilities})`,
              zIndex: -1,
            }}>
              <Carousel.Caption className="carousel-caption-mobi">
                <h2 className="title-banner">OPENTECHIZ</h2>
                <p className="p-banner">Effective Team-Working</p>
                <div className="clear" />
              </Carousel.Caption>
            </BackgroundImages>
          </Carousel.Item>
          <Carousel.Item>
            <BackgroundImages style={{
              backgroundImage: `url(${slide2})`,
              zIndex: -1,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}>
              <Carousel.Caption className="carousel-caption-mobi">
                <h2 className="title-banner mobi">OPENTECHIZ</h2>
                <p className="p-banner mobi">We use the best technologies for <br/> the development of Web apps and Mobile Apps</p>
                <div className="clear" />
              </Carousel.Caption>
            </BackgroundImages>
          </Carousel.Item>
          <Carousel.Item>
            <BackgroundImages style={{
              backgroundImage: `url(${slide1})`,
              zIndex: -1,
            }}>
              <Carousel.Caption className="carousel-caption-mobi">
                <h2 className="title-banner mobi">OPENTECHIZ</h2>
                <p className="p-banner mobi">
                  Always think with our clients
                </p>
                <div className="clear" />
              </Carousel.Caption>
            </BackgroundImages>
          </Carousel.Item>
        </Carousel>
      </div>
    );
  }
}

export default SlideShow;
